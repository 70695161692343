<template>
  <div class="home">
    <div class="mainTop">
      武汉艾瑞美迪医疗管理咨询有限公司
      <div class="fl">
        <img src="../assets/images/logo.png" class="logo" />
      </div>
      <div class="fr frDiv">
        <!-- <div class="fl show" style="cursor: pointer;" @click="goBroadInfo">
          <img src="../assets/images/tips.png" class="tips finger" />
          <span>公告</span>
        </div> -->
        <div class="fl show">
          <!-- <span>恒瑞新创（北京）科技有限公司</span> -->
          <Dropdown>
            <a href="javascript:void(0)">
              <img src="../assets/images/head.png" class="head finger" />
            </a>
            <DropdownMenu slot="list">
              <!-- <DropdownItem @click.native="userRole"><Icon type="ios-contact-outline" class="iconClass" />用户信息</DropdownItem> -->
              <!-- <DropdownItem @click.native="systemMsg"><Icon type="ios-mail-outline" class="iconClass" />系统消息</DropdownItem> -->
              <DropdownItem @click.native="changePwd"> <Icon type="ios-lock-outline" class="iconClass" />修改密码 </DropdownItem>
              <DropdownItem @click.native="loginOut"> <Icon type="ios-power-outline" class="iconClass" />退出登录 </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <span style="margin-left: 14px;">{{ user_name }}</span>
        </div>
      </div>
    </div>
    <div class="mainBtm">
      <div @mouseleave="childMenuStatus = false;child2MenuStatus = false;">
        <div class="cententFl">
          <!-- <menu-com></menu-com> -->
          <div v-for="(item, index) in menuList" :key="item.title + index" class="item finger">
            <div :class="{ itemDiv: true, finger: true, isClick: item.isClick }" @click="clickMenu(item, index)" @mouseenter="mouseoverMenu(item, index)">
              <img :src="item.src" v-if="!item.isClick" />
              <img :src="item.clickSrc" v-else />
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
        <transition name="slide-fade">
          <div :class="['secondMenu',secondMenu[0]?secondMenu[0].children?'secondMenuyy':'':'']" v-show="childMenuStatus">
            <!-- <div v-for="(item, index) in secondMenu" :key="index" :class="{ secondChild: true, finger: true, secondClick: item.isClick ,secondHover:item.children?true:false}" @click="secondClick(item, index)" @mouseenter="mouseoverChildMenu(item, index)"> -->
            <div v-for="(item, index) in secondMenu" :key="index" :class="{ secondChild: true, finger: true, secondClick: item.isClick}" @click="secondClick(item, index)" @mouseenter="mouseoverChildMenu(item, index)">
            <!-- <div v-for="(item, index) in secondMenu" :key="index" :class="{ secondChild: true, finger: true, secondClick: item.isClick}" @click="secondClick(item, index)"> -->
              <span>{{ item.title }}</span>
              <span class="secondRight" v-if="item.isClick">></span>
            </div>
          </div>
        </transition>
        <transition name="slide-fade">
          <div class="secondMenuChild" @mouseenter="mouseover2ChildMenu(item, index)" v-show="child2MenuStatus">
            <div v-for="(item, index) in second2Menu" :key="index" :class="{ secondChild: true, finger: true, secondClick: item.isClick}" @click="secondClick2(item, index)">
              <span>{{ item.title }}</span>
              <!-- <span class="secondRight" v-if="item.isClick">></span> -->
            </div>
          </div>
        </transition>
      </div>
      <div class="cententFr">
        <!--        <div class="navBox" v-if="navList[0] != '首页'">-->
        <!--          <div v-for="(item, index) in navList" :key="index">{{ item }}<span class="marginItiem" v-if="index < navList.length - 1 && item">/</span></div>-->
        <!--        </div>-->
        <div class="tagNav" v-if="navList[0] != '首页'">
          <div class="btn-con left-btn" @click="navScroll(240)">
            <Button type="text">
              <Icon :size="18" type="ios-arrow-back" />
            </Button>
          </div>
          <div class="scroll-nav" ref="scrollNav">
            <!--            <Tag type="dot" color="default" class="finger">首页 navSelect</Tag>-->
            <div class="init-nav" :style="{ left: tagBodyLeft + 'px' }" ref="scrollInit">
              <Tag type="dot" v-for="(item, index) in navSelect" :key="index" :color="item.color" closable class="finger" @click.native="selectTag(item, index)" @on-close="closeTag(item, index)">{{ item.title }}</Tag>
            </div>
          </div>
          <div class="btn-con right-btn" @click="navScroll(-240)">
            <Button type="text">
              <Icon :size="18" type="ios-arrow-forward" />
            </Button>
          </div>
        </div>
        <div v-if="globalLoading" style="height: 100%; width: 100%; position: absolute;">
          <Spin fix>
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
            <div>加载中...</div>
          </Spin>
        </div>
        <keep-alive v-if="keepAliveSH" :exclude="celarCacheList">
          <router-view class="router_view" v-if="isRoterAlive" />
        </keep-alive>

        <!-- <router-view  class="router_view" v-if="isRoterAlive && !$route.meta.keepAlive" /> -->
      </div>
    </div>
    <div class="mainBot">
      <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2020022570号-1   武汉艾瑞美迪医疗管理咨询有限公司</a>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import jscRouter from '../router/jscRouter.js'
export default {
  provide() {
    return {
      reload: this.reload,
    }
  },
  watch: {
    globalLoading: {
      handler(newVal, oldVal) {
        //  this.isLoad = this.tabloading
      },
      immediate: true,
    },
  },
  data() {
    return {
      keepAliveSH: true,
      tagBodyLeft: 0,
      home: 'home',
      menuList: [], // 菜单列表
      time: '',
      tips: false,
      childMenuStatus: false,
      secondMenu: [],
      showIndex: 0, // 那个1级模块
      showIndex2:0, //2级模块
      title: '',
      navName: '',
      close: false,
      user_name: '',
      setTime: '',
      messageList: [],
      tagList: [],
      num: 0,
      navList: [],
      isRoterAlive: true,
      cacheDocs: ['purchaseAndSale'],
      // 如果需要keepalive,下面列表是名单，有名字代表销毁
      celarCacheList: [
        'lookListTable',
        'choiseListTable',
        'choiseListTableKehu',
        'addAllocate',
        'invoiceManage',
        'monthlySupplyListSec',
        'monthlyProductStatisticsDetail',
        'OrderEqualRecordDetail',
        'detailsInventorySurplus',
        'SupplierEvaluationform',
        'businessApproval',
        'purchaseAndSaleFirstDetail',
        'detailsTirdFactorySupplyList',
        'detailsThirdSupplierSupplyList',
        'entrustSettlementDetails',
        'purchaseRequisition1',
        'salesOrderDetails',
        'purchaseReturnsDetails',
        'purchaseRequisition',
        'purchaseOrderDetails',
        'detailsPurchaseReturnRecord',
        'detailsWarehouseReceiptRecord',
        'detailsPickingListRecord',
        'detailsWarehouseEntryRecord',
        'detailsAcceptanceRecord',
        'detailsReceiptRecord',
        'detailsWarehouseOutTable',
        'detailsCustomerSalesTable',
        'detailsFactorySupplyList',
        'detailsSupplierSupplyList',
        'addChangeBatch1',
        'inventoryStockDetail',
        'changeAllocate',
        'detailsInventoryManage',
        'customerScaleAdd',
        'newZinvoice',
        'addCustomerInvoices',
        'editInvoices',
        'addChangeBatch',
        'addInventoryStock',
        'salesReturnEdit',
        'salesOrderAdd',
        'salesApplicationEdit',
        'addPurchaseOrder',
        'quotationAdd',
        'editPurchaseReturn',
        'addPurchaseReturns',
        'editPurchaseOrder',
        'addPurchaseOrder',
        'editPurchaseRequisition',
        'addPurchaseRequisition',
        'clientManageFrame',
        'productFrame',
        'firstCampApproval',
        'manufacturerManage',
        'addAllocateConsign',
        'monthlySupplyListThi',
        'monthlySupplyListSecConsign',
        'monthlyProductStatisticsDetailConsign',
        'detailsInventorySurplusConsign',
        'purchaseAndSaleFirstDetailConsign',
        'detailsTirdFactorySupplyListConsign',
        'detailsThirdSupplierSupplyListConsign',
        'entrustSettlementDetailsConsign',
        'salesOrderDetailsConsign',
        'purchaseReturnsDetailsConsign',
        'purchaseRequisitionConsign',
        'purchaseOrderDetailsConsign',
        'detailsPurchaseReturnRecordConsign',
        'detailsWarehouseReceiptRecordConsign',
        'detailsPickingListRecordConsign',
        'detailsWarehouseEntryRecordConsign',
        'detailsAcceptanceRecordConsign',
        'detailsReceiptRecordConsign',
        'detailsWarehouseOutTableConsign',
        'detailsCustomerSalesTableConsign',
        'detailsFactorySupplyListConsign',
        'detailsSupplierSupplyListConsign',
        'inventoryStockDetailConsign',
        'customerScaleAddConsign',
        'addChangeBatchConsign',
        'addInventoryStockConsign',
        'salesReturnEditConsign',
        'salesOrderAddConsign',
        'salesApplicationEditConsign',
        'addPurchaseOrderConsign',
        'quotationAddConsign',
        'editPurchaseReturnConsign',
        'addPurchaseReturnsConsign',
        'editPurchaseOrderConsign',
        'editPurchaseRequisitionConsign',
        'addPurchaseRequisitionConsign',
        'salesOrderConsign',
        'inventoryManageDetailConsign',
        'inventoryStockToExaminsConsign',
        'monthlySupplyListThiConsign'
      ],
      navSelect: [],
      child2MenuStatus:false,
      second2Menu: [],
      jscRouterThreeAll:this.getJscRouterThreeAll(),
      jscOneTitles:['寄售仓','寄售业务'],
    }
  },
  computed: {
    ...mapState(['breadcrumb', 'clientNameList', 'userInfo', 'globalLoading']),
  },
  mounted() {
    if (this.$route.path === '/home') {
      this.$router.push('/main')
    }
    if (this.$route.meta.triName) {
      this.navList = [this.$route.meta.title, this.$route.meta.navName, this.$route.query.triName ? this.$route.query.triName : this.$route.meta.triName]
    } else if (this.$route.meta.navName) {
      this.navList = [this.$route.meta.title, this.$route.meta.navName]
    } else {
      this.navList = [this.$route.meta.title]
    }
    this.queryMenuList()
    this.querySupplierSelect() // 客户名称
    this.querySupplierList() // 供应商
  },
  methods: {
    getJscRouterThreeAll(){
      let keys = Object.keys(jscRouter);
      let routerThreeAll=[]
      for(let i=0;i<keys.length;i++){
        routerThreeAll= routerThreeAll.concat(jscRouter[keys[i]]);
      }
      return routerThreeAll;
    },
    navScroll(num) {
      const scrollNav = this.$refs.scrollNav.offsetWidth
      // offsetWidth元素的实际宽度
      const scrollInit = this.$refs.scrollInit.offsetWidth
      // 如果num大于0 里面的数据往左走
      if (num > 0) {
        // Math.min() 方法可返回指定的数字中带有最低值的数字
        this.tagBodyLeft = Math.min(0, this.tagBodyLeft + num)
      } else {
        // 如果num小于0 里面的数据往右走
        // 如果最大的宽度 大于当前宽度 偏移量是0
        if (scrollNav > scrollInit) {
          this.tagBodyLeft = 0
        } else {
          // 如果说 固定的宽度 小于了已经有的宽度 就让他偏移
          let dis = scrollNav - scrollInit
          if (this.tagBodyLeft > -dis) {
            this.tagBodyLeft = this.tagBodyLeft
          } else {
            this.tagBodyLeft = Math.max(this.tagBodyLeft + num, scrollNav - scrollInit)
          }
        }
      }
    },
    // 更换已选
    selectTag(item, index) {
      this.$router.push({
        path: item.path,
        query: item.query,
      })
      this.menuChangeFalse()
      if(this.setjscisClick(item)){
        return
      }
      this.menuList[item.fristIndex].isClick = true
      this.menuList[item.fristIndex].children[item.secondIndex].isClick = true
      // this.$router.push(item.path)
    },
    // 关闭标签
    closeTag(item, index) {
      this.navSelect.forEach((item2, index) => {
        if (item2.path == item.path) {
          this.navSelect.splice(index, 1)
        }
      })
      // 如果数组是空的  就跳转到/home
      if (this.navSelect.length == 0) {
        this.menuChangeFalse()
        this.$router.replace({
          path: '/home',
        })
        this.menuList[0].isClick = true
      } else {
        this.navSelect = JSON.parse(JSON.stringify(this.navSelect))
        setTimeout(() => {
          const scrollNav = this.$refs.scrollNav.offsetWidth
          // offsetWidth元素的实际宽度
          const scrollInit = this.$refs.scrollInit.offsetWidth
          let num = scrollInit + this.tagBodyLeft
          if (scrollNav > num) {
            let dis = this.tagBodyLeft + scrollNav - num
            this.tagBodyLeft = dis < 0 ? dis : 0
          }
        }, 200)
        // 如果删除的是选中的状态 才跳转路由
        if (item.color == 'primary') {
          this.menuChangeFalse()
          // 如果前面一个存在就跳到前面
          if (this.navSelect[index - 1]) {
            this.$router.replace({
              path: this.navSelect[index - 1].path,
              title: this.navSelect[index - 1].title,
              color: this.navSelect[index - 1].color,
              query: this.navSelect[index - 1].query,
              params: this.navSelect[index - 1].params,
              fristIndex: this.navSelect[index - 1].fristIndex,
              secondIndex: this.navSelect[index - 1].secondIndex,
            })
            this.navSelect[index - 1].color = 'primary'
            if(this.setjscisClick(this.navSelect[index - 1])){
              return null
            }
            this.menuList[this.navSelect[index - 1].fristIndex].isClick = true
            this.menuList[this.navSelect[index - 1].fristIndex].children[this.navSelect[index - 1].secondIndex].isClick = true
            return null
          }
          // 如果前面不存在就跳到后面
          if (this.navSelect[index]) {
            this.navSelect[index].color = 'primary'
            this.$router.replace({
              path: this.navSelect[index].path,
              title: this.navSelect[index].title,
              color: this.navSelect[index].color,
              query: this.navSelect[index].query,
              params: this.navSelect[index].params,
              fristIndex: this.navSelect[index].fristIndex,
              secondIndex: this.navSelect[index].secondIndex,
            })
            if(this.setjscisClick(this.navSelect[index])){
              return null
            }
            this.menuList[this.navSelect[index].fristIndex].isClick = true
            this.menuList[this.navSelect[index].fristIndex].children[this.navSelect[index].secondIndex].isClick = true
            return null
          }
        }
      }
    },
    setjscisClick(navSelectItem){
      //判断是不是寄售仓的三级页面 这里本来应该以名字判断
      if(navSelectItem.title.indexOf('-')==-1){
        return false
      }
      let titles = navSelectItem.title.split('-');
      let jscTitle=titles[0];
      if(!jscTitle){
        return false
      }
      // if(jscTitle.length>3){
      //   jscTitle = jscTitle.substring(0,3);
      // }
      if(this.jscOneTitles.includes(jscTitle)){
        console.log('当前选择的前一项是寄售仓或寄售业务',navSelectItem.path);
        let befoItem={};
        this.jscRouterThreeAll.forEach(item=>{
          if(item.link == navSelectItem.path){
            befoItem=JSON.parse(JSON.stringify(item));
          }
        })
        this.menuList.map(item=>{
          //if(item.func_name == '寄售仓'){
          if(this.jscOneTitles.includes(item.func_name)){
            item.isClick = true
            item.children.map(item1=>{
              if(befoItem.p_func_id){
                if(item1.func_id == befoItem.p_func_id){
                  item1.isClick = true
                  item1.children.map(item2=>{
                    if(befoItem.func_id){
                      if(item2.func_id == befoItem.func_id){
                        item2.isClick =true
                      }
                    }
                  })
                }
              }
            })
          }
        })
        return true
      }
    },
    reload() {
      this.isRoterAlive = false
      this.$nextTick(() => {
        this.isRoterAlive = true
      })
    },
    // 供应商名称
    querySupplierList() {
      this.$http.fetch(this.$api.supplierSelect, true).then(res => {
        if (res.data.length > 0) {
          this.$store.commit('setSupplierNameList', res.data)
        }
      })
    },
    // 查询客户名称列表
    querySupplierSelect() {
      this.$http.fetch(this.$api.customerList, true).then(res => {
        if (res.data.length > 0) {
          this.$store.commit('setClientNameList', res.data)
        }
      })
    },
    // 退出登录
    loginOut() {
      this.$http.post(this.$api.systemLoginOut, {}, true).then(res => {
        this.$Message.success('退出成功')
        localStorage.removeItem('adminohoqicbi==')
        localStorage.removeItem('userRole')
        this.$router.replace('/login')
      })
    },
    // 修改密码
    changePwd() {
      this.$router.push('/changePwd')
    },
    // 系统消息
    systemMsg() {
      this.$router.push('/systemMessage')
    },
    // 用户信息
    userRole() {
      this.$router.push('/user_role')
    },
    //  点击主菜单
    clickMenu(item, index) {
      for (let i = 0; i < this.menuList.length; i++) {
        this.$set(this.menuList[i], 'isClick', false)
      }
      item.isClick = true
      // if (index == 0 && item.link == '/main') {
      //   if (this.$route.path == '/main') {
      //     return null
      //   } else {
      //     this.$router.push('/main')
      //   }
      // }
      if (item.func_name == '首页') {
        this.$router.push('/main')
      }
    },
    // 公告图标跳转
    goBroadInfo() {
      this.$router.push({
        path: '/notice',
      })
    },
    menuChangeFalse() {
      for (let i = 0; i < this.menuList.length; i++) {
        this.$set(this.menuList[i], 'isClick', false)
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          this.$set(this.menuList[i].children[j], 'isClick', false)
          if (this.menuList[i].children[j].children) {
            for (let h = 0; h < this.menuList[i].children[j].children.length; h++) {
              this.$set(this.menuList[i].children[j].children[h], 'isClick', false)
            }
          }
        }
      }
    },
    // 点击二级菜单
    secondClick(item, index) {
      if(item.children){
        return null
      }
      if (item.path === this.$route.path) {
        return null
      }
      for (let i = 0; i < this.menuList.length; i++) {
        this.$set(this.menuList[i], 'isClick', false)
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          this.$set(this.menuList[i].children[j], 'isClick', false)
          if (this.menuList[i].children[j].children) {
            for (let h = 0; h < this.menuList[i].children[j].children.length; h++) {
              this.$set(this.menuList[i].children[j].children[h], 'isClick', false)
            }
          }
        }
      }
      this.menuList[this.showIndex].isClick = true
      item.isClick = true
      this.$router.push(item.path)

      // 处理点返回不清理一级页面的数据
      sessionStorage.removeItem('search')
      sessionStorage.removeItem('page')
    },
    secondClick2(item, index){
      if (item.link === this.$route.path) {
        return null
      }
      for (let i = 0; i < this.menuList.length; i++) {
        this.$set(this.menuList[i], 'isClick', false)
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          this.$set(this.menuList[i].children[j], 'isClick', false)
          if (this.menuList[i].children[j].children) {
            for (let h = 0; h < this.menuList[i].children[j].children.length; h++) {
              this.$set(this.menuList[i].children[j].children[h], 'isClick', false)
            }
          }
        }
      }
      this.menuList[this.showIndex].isClick = true
      this.menuList[this.showIndex].children[this.showIndex2].isClick = true
      item.isClick = true
      this.$router.push(item.link)
      this.child2MenuStatus = false
      this.childMenuStatus = false
      // 处理点返回不清理一级页面的数据
      sessionStorage.removeItem('search')
      sessionStorage.removeItem('page')
    },
    // 主菜单移入事件
    mouseoverMenu(item, index) {
      // console.log(item, index);
      this.child2MenuStatus=false
      for (let i = 0; i < this.menuList.length; i++) {
        this.$set(this.menuList[i], 'isClick', false)
      }
      item.isClick = true

      if (index >= 0) {
        if (item.children.length > 0) {
          this.childMenuStatus = true
          this.showIndex = index
          this.secondMenu = item.children
        } else {
          this.childMenuStatus = false
        }
      } else {
        this.childMenuStatus = false
      }
    },
    mouseoverChildMenu(item, index) {
      //子菜单移入事件
      console.log(item,index);

      if (index >= 0) {
        if (item.children.length > 0) {
          this.child2MenuStatus = true
          this.showIndex2 = index
          this.second2Menu = item.children
        } else {
          this.child2MenuStatus = false
        }
      } else {
        this.child2MenuStatus = false
      }
      // if(item.children){
      //   this.child2MenuStatus = true
      // }
    },
    mouseover2ChildMenu(item, index) {
      //子菜单移入事件
      this.childMenuStatus = true;
      this.child2MenuStatus = true
    },
    // 查询菜单列表
    async queryMenuList() {
      let res = await this.$http.get(this.$api.permissionRoleFunc, {})
      this.$store.commit('setHomePermission', res.data[0].children[0].permission.search)
      res.data[0].children = []
      await this.$store.commit('setRoleMenuList', res.data)
      this.menuList = res.data
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i].src = this.menuList[i].src.replace('images', 'static')
        this.menuList[i].clickSrc = this.menuList[i].clickSrc.replace('images', 'static')
        this.$set(this.menuList[i], 'isClick', false)
        this.$set(this.menuList[i], 'title', this.menuList[i].func_name)
        this.$set(this.menuList[i], 'path', this.menuList[i].link)
        if (this.$route.path == this.menuList[i].path) {
          this.$set(this.menuList[i], 'isClick', true)
        }
        if (this.menuList[i].children) {
          for (let j = 0; j < this.menuList[i].children.length; j++) {
            this.$set(this.menuList[i].children[j], 'isClick', false)
            this.$set(this.menuList[i].children[j], 'title', this.menuList[i].children[j].func_name)
            this.$set(this.menuList[i].children[j], 'path', this.menuList[i].children[j].link)

            // 将列表中的权限存入到sessionStorage中
            let navName = this.menuList[i].children[j].func_name
            let rightList = this.menuList[i].children[j].permission
            rightList = JSON.stringify(rightList)
            window.sessionStorage.setItem(navName, rightList)

            // if (this.menuList[i].children[j].children) {
            //   for (let h = 0; h < this.menuList[i].children[j].children.length; h++) {
            //     this.$set(this.menuList[i].children[j].children[h], 'isClick', false)
            //   }
            // }
            // if (this.$route.path == this.menuList[i].children[j].link) {
            //   this.$set(this.menuList[i], 'isClick', true)
            //   this.$set(this.menuList[i].children[j], 'isClick', true)
            //   this.navSelect.push({
            //     path: this.menuList[i].children[j].link,
            //     title: this.menuList[i].children[j].title,
            //     color: 'primary',
            //     fristIndex: i,
            //     secondIndex: j,
            //   })
            // }
          }
        }
      }

      //添加一条寄售仓三级路由
      this.menuList.map(item =>{
        //if(item.func_name == '寄售仓'){
        if(this.jscOneTitles.includes(item.func_name)){
          item.children.map(citem=>{
            if(citem.link == 'purchasing'){
              citem.children=jscRouter.cggl
            }
            if(citem.link == 'sale'){
              citem.children=jscRouter.xxgl
            }
            if(citem.link == 'warehouse'){
              citem.children=jscRouter.ckgl
            }
            if(citem.link == 'report'){
              citem.children=jscRouter.bbzx
            }
            if(citem.link == 'gsp'){
              citem.children=jscRouter.GSPgl
            }
            if(citem.link == 'jurisdiction'){
              citem.children=jscRouter.qxgl
            }
          })
        }
      })

      let quanxianArr = []
      let quanxian = ''
      if (this.menuList.length > 0) {
        this.menuList.forEach(item => {
          if (item.func_name == 'GSP管理') {
            quanxianArr = item.children
          }
        })
        quanxianArr.forEach(item2 => {
          if (item2.func_name == '入库单') {
            quanxian = item2.permission.modify
          }
        })
      }
      localStorage.setItem('quanxian', quanxian)
    },
  },
  watch: {
    $route(to, from) {
      if (sessionStorage.getItem('keepalive') == 'clearCache') {
        // this.keepAliveSH = false
        // this.$nextTick(() => {
        //   this.keepAliveSH = true
        // })
      }
      // if(sessionStorage.getItem('updataCache') == 0) {
      //   this.keepAliveSH = false
      //   this.$nextTick(() => {
      //     this.keepAliveSH = true
      //   })
      // }
      // 原来的面包屑处理
      if (to.meta.triName) {
        this.navList = [to.meta.title, to.meta.navName, this.$route.query.triName ? this.$route.query.triName : to.meta.triName]
      } else if (to.meta.navName) {
        this.navList = [to.meta.title, to.meta.navName]
      } else {
        this.navList = [to.meta.title]
      }

      // lfz tag 处理
      var title = ''
      if (Object.keys(to.meta).length == 1) {
        title = to.meta.title
      } else if (Object.keys(to.meta).length == 2) {
        title = to.meta.title + (to.meta.navName ? '-' + to.meta.navName : '')
      } else if (Object.keys(to.meta).length === 3) {
        title = to.meta.title + (to.meta.navName ? '-' + to.meta.navName : '') + (to.meta.triName ? '-' + to.meta.triName : '')
      }
      this.navSelect.forEach((item, index) => {
        item.color = 'default'
      })
      if (to.path == '/main') {
        this.menuChangeFalse()
        this.menuList[0].isClick = true
        return
      }
      // 检查 navSelect中是否已经 存在了要跳转的地址
      for (let i = 0; i < this.navSelect.length; i++) {
        if (this.navSelect[i].path == to.path) {
          this.navSelect[i].color = 'primary'
          localStorage.setItem('navSelect', JSON.stringify(this.navSelect))
          return
        }
      }

      // 如果没有 添加过的 就新增 然后存在本地
      var fristIndex = 0
      var secondIndex = 0
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].children) {
          for (let j = 0; j < this.menuList[i].children.length; j++) {
            if (this.menuList[i].children[j].title == to.meta.navName) {
              fristIndex = i
              secondIndex = j
              to.meta.title = this.menuList[i].func_name
              to.meta.navName = this.menuList[i].children[j].func_name
            }
          }
        }
      }

      //修复tag寄售仓三级导航错误
      let jscRouter=this.jscRouterThreeAll.filter(item=>{
        if(item.link ==to.path){
          return item
        }
      })
      if(jscRouter.length>0){
        //是寄售仓偶尔会出现异常所以直接将title全部替换成寄售仓-xxx
        let titles= title.split('-');
        if(titles[1].indexOf('Consign')>0){
          titles[1] = titles[1].replace('Consign','');
        }
        let jscOne= this.menuList.filter(item=>{
          // if(item.func_name == '寄售仓' || item.func_name == '寄售业务'){
          //   return item
          // }
          if(this.jscOneTitles.includes(item.func_name)){
            return item
          }
        })
        let jscOneTitle = jscOne[0]?jscOne[0].func_name:this.jscOneTitle[0];
        this.navSelect.push({
          path: to.path,
          title: jscOneTitle+'-'+titles[1],
          color: 'primary',
          query: to.query,
          params: to.params,
          fristIndex: fristIndex,
          secondIndex: secondIndex,
        })
      }else{
        //不是寄售仓的全部按照以前路由拼接tag
        if(title.indexOf('Consign')>0){
          title = title.replace('Consign','');
        }
        this.navSelect.push({
          path: to.path,
          title: title,
          color: 'primary',
          query: to.query,
          params: to.params,
          fristIndex: fristIndex,
          secondIndex: secondIndex,
        })
      }


      // this.navSelect.push({
      //   path: to.path,
      //   title: title,
      //   color: 'primary',
      //   query: to.query,
      //   params: to.params,
      //   fristIndex: fristIndex,
      //   secondIndex: secondIndex,
      // })
      
      this.$nextTick(() => {
        const scrollNav = this.$refs.scrollNav.offsetWidth
        // offsetWidth元素的实际宽度
        const scrollInit = this.$refs.scrollInit.offsetWidth
        if (scrollInit > scrollNav) {
          this.tagBodyLeft = scrollNav - scrollInit
        }
      })
      localStorage.setItem('navSelect', JSON.stringify(this.navSelect))
    },
  },
  created() {
    this.navSelect = localStorage.getItem('navSelect') ? JSON.parse(localStorage.getItem('navSelect')) : []
    let username = window.localStorage.getItem('username')
    /* let username = this.userInfo.user_name */
    this.user_name = username
    // 防止函数触发多次
    this.$root.eventHub.$off('updateRightList')
    // 接受信息，运行权限列表查询
    this.$root.eventHub.$on('updateRightList', () => {
      this.queryMenuList()
      console.log('权限已更新')
    })
  },
}
</script>
<style lang="less" scoped>
.scroll-nav {
  flex: 1;
  margin: 0 10px;
  position: relative;
  // 如果溢出可以滑动
  overflow-x: auto;
}
.init-nav {
  // 内容不会被修剪，会呈现在元素框之外。
  overflow: visible;
  // 规定段落的文本不换行
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 0;
}
.scroll-nav::-webkit-scrollbar {
  height: 0px !important;
}
.btn-con {
  height: 100%;
  background: #fff;
  padding-top: 3px;
  button {
    padding: 6px 4px;
    line-height: 14px;
    text-align: center;
  }
}
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .mainTop {
    z-index: 11;
    height: 85px;
    background: rgba(55, 63, 105, 1);
    box-shadow: 0.03rem 4px 15px rgba(39, 37, 37, 0.479);
    padding: 0 30px 0 33px;
    color: #fff;
    font-size: 18px;
    line-height: 85px;
    text-align: center;
    .logo {
      margin-top: 19px;
      width: 269px;
      height: 48px;
    }
    .frDiv {
      height: 100%;
      .show {
        height: 100%;
        margin-left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .tips {
          width: 24px;
          height: 29px;
          margin-right: 11px;
        }
        .head {
          width: 52px;
          height: 52px;
          border-radius: 100%;
          margin-left: 11px;
          margin-top: 32px;
        }
      }
    }
  }
  .mainBtm {
    flex: 1;
    display: flex;
    overflow-y: auto;
    position: relative;
    .secondMenu {
      z-index: 10001;
      width: 280px;
      height: 100%;
      background: #373f69;
      position: absolute;
      top: 0;
      left: 100px;
      // z-index: 100;
      font-size: 16px;
      overflow-y: auto;
      .secondRight {
        float: right;
        transform: scaleY(2);
      }
      .secondChild {
        height: 60px;
        line-height: 60px;
        color: #fff;
        padding: 0 23px 0 34px;
      }
      .secondClick {
        background: #426cfc;
      }
      .secondHover:hover{
        background: #ffffff;
        color: #426cfc;
      }
    }
    .secondMenuyy {
      box-shadow: 0.025rem 3px 10px rgba(39, 37, 37, 0.479);
    }
    .cententFl {
      z-index: 10002;
      height: 100%;
      overflow-y: auto;
      width: 100px;
      background: #373f69;
      position: relative;
      box-shadow: 0.025rem 3px 10px rgba(39, 37, 37, 0.479);
      .item {
        font-size: 16px;
        line-height: 22px;
        color: #7d8acd;
        text-align: center;
        border-bottom: 2px solid #3e4673;
        height: 100px;
        box-sizing: border-box;
        img {
          width: 30px;
          height: 30px;
          margin-top: 20px;
          margin-bottom: 5px;
        }
      }
      .item:last-child {
        border: none;
      }
      .itemDiv {
        width: 100%;
        height: 100%;
      }
      .isClick {
        background: #ffffff;
        color: #426cfc;
      }
    }
    .cententFr {
      padding: 20px 30px 30px 30px;
      flex: 1;
      background: #f0f0f0;
      overflow-y: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      .router_view {
        flex: 1;
        background: #fff;
        border-radius: 8px;
        overflow-y: auto;
        height: 100%;
      }
      .tagNav {
        height: 36px;
        margin-bottom: 10px;
        position: relative;
        display: flex;
      }
      .navBox {
        display: flex;
        margin-bottom: 17px;
        div {
          font-size: 16px;
          color: rgba(153, 153, 153, 1);
        }
        .marginItiem {
          margin: 0 10px;
        }
        div:last-child {
          color: #333333;
        }
      }
    }
    .secondMenuChild{
      z-index: 10000;
      width: 280px;
      height: 100%;
      background: #373f69;
      position: absolute;
      top: 0;
      left: 380px;
      // z-index: 100;
      font-size: 16px;
      overflow-y: auto;
      .secondRight {
        float: right;
        transform: scaleY(2);
      }
      .secondChild {
        height: 60px;
        line-height: 60px;
        color: #fff;
        padding: 0 23px 0 34px;
      }
      .secondClick {
        background: #426cfc;
      }
      .secondHover:hover{
        background: #ffffff;
        color: #426cfc;
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translatex(-10px);
  opacity: 0;
}

.topNav {
  margin-bottom: 20px;
  // overflow: hidden;
  display: flex;
  .showBox {
    flex: 1;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    // position: relative;
    .boxClass {
      // position: absolute;
    }
  }
  .showBox::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
  .boxClass::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
}
// 按钮内的懒加载样式
.loadingStyle {
  display: inline-block;
}
.keep-alive {
  width: 100%;
  height: 100%;
  position: relative;
}
.mainBot {
  a {
    color: #333;
  }
  position: absolute;
  bottom: 0;
  left: 900px;
  text-align: center;
}
</style>
