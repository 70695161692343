let cggl=[
  // {
  //   func_id: "11001",
  //   func_name: "采购申请单",
  //   link: "/purchaseRequisitionConsign",
  //   p_func_id: "1401",
  //   permission: {insert: true, delete: true, modify: true, search: true},
  //   rank: "3",
  //   title: "采购申请单"
  // },
  {
    func_id: "11002",
    func_name: "采购订单",
    link: "/purchaseOrderConsign",
    p_func_id: "1401",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "采购订单"
  },
  {
    func_id: "11003",
    func_name: "采购退货通知单",
    link: "/purchaseReturnsConsign",
    p_func_id: "1401",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "采购退货通知单"
  }
]

let xxgl=[
  {
    func_id: "12001",
    func_name: "销售订单",
    link: "/salesOrderConsign",
    p_func_id: "1402",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "销售订单"
  },
  {
    func_id: "12002",
    func_name: "销售退货通知单",
    link: "/salesReturnConsign",
    p_func_id: "1402",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "销售退货通知单"
  }
]

let ckgl=[
  {
    func_id: "13001",
    func_name: "收货验收",
    link: "/receivingAcceptanceConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "收货验收"
  },
  {
    func_id: "13002",
    func_name: "入库",
    link: "/enterStorageConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "入库"
  },
  {
    func_id: "13003",
    func_name: "拣货",
    link: "/orderPickingConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "拣货"
  },
  {
    func_id: "13004",
    func_name: "出库",
    link: "/outStorageConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "出库"
  },
  {
    func_id: "13005",
    func_name: "库存管理",
    link: "/inventoryManageConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "库存管理"
  },
  {
    func_id: "13006",
    func_name: "调拨",
    link: "/allocateConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "调拨"
  },
  {
    func_id: "13007",
    func_name: "仓库盘存",
    link: "/inventoryStockConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "仓库盘存"
  },
  {
    func_id: "13008",
    func_name: "在库养护",
    link: "/maintenanceConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "在库养护"
  },
  {
    func_id: "13009",
    func_name: "批号修改",
    link: "/changeBatchConsign",
    p_func_id: "1403",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "批号修改"
  },
]

let bbzx=[
  {
    func_id: "16001",
    func_name: "供应商月度统计",
    link: "/monthlySupplyListConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "供应商月度统计"
  },
  {
    func_id: "16002",
    func_name: "产品月度统计",
    link: "/monthlyProductStatisticsConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "产品月度统计"
  },
  {
    func_id: "16003",
    func_name: "进销存变动表",
    link: "/purchaseAndSaleConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "进销存变动表"
  },
  {
    func_id: "16004",
    func_name: "供应商供货表",
    link: "/supplierSupplyListConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "供应商供货表"
  },
  {
    func_id: "16005",
    func_name: "厂家供货表",
    link: "/factorySupplyListConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "厂家供货表"
  },
  {
    func_id: "16006",
    func_name: "客户销售表",
    link: "/customerSalesTableConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "客户销售表"
  },
  {
    func_id: "16007",
    func_name: "仓库出库表",
    link: "/warehouseOutTableConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "仓库出库表"
  },
  {
    func_id: "16008",
    func_name: "产品追溯表",
    link: "/productTraceabilitySheetConsign",
    p_func_id: "1406",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "产品追溯表"
  },
]

let GSPgl=[
  {
    func_id: "17001",
    func_name: "调拨记录",
    link: "/transferRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "调拨记录"
  },
  {
    func_id: "17002",
    func_name: "采购产品记录",
    link: "/purchaseRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "采购产品记录"
  },
  {
    func_id: "17003",
    func_name: "收货单记录",
    link: "/receiptRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "收货单记录"
  },
  {
    func_id: "17004",
    func_name: "收货产品记录",
    link: "/recordProductsReceivedConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "收货产品记录"
  },
  {
    func_id: "17005",
    func_name: "验收记录",
    link: "/acceptanceRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "验收记录"
  },
  {
    func_id: "17006",
    func_name: "验收产品记录",
    link: "/acceptanceProductRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "验收产品记录"
  },
  {
    func_id: "17007",
    func_name: "入库单",
    link: "/warehouseEntryRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "入库单"
  },
  {
    func_id: "17008",
    func_name: "入库记录",
    link: "/recordIncomingProductsConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "入库记录"
  },
  {
    func_id: "17009",
    func_name: "采购退货产品记录",
    link: "/purchaseReturnedProductRecordsConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "采购退货产品记录"
  },
  {
    func_id: "17010",
    func_name: "销售产品记录",
    link: "/salesRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "销售产品记录"
  },
  {
    func_id: "17011",
    func_name: "拣货单记录",
    link: "/pickingListRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "拣货单记录"
  },
  {
    func_id: "17012",
    func_name: "拣货产品记录",
    link: "/recordPickingProductsConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "拣货产品记录"
  },
  {
    func_id: "17013",
    func_name: "出库单",
    link: "/warehouseReceiptRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "出库单"
  },
  {
    func_id: "17014",
    func_name: "出库记录",
    link: "/outgoingProductRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "出库记录"
  },
  {
    func_id: "17015",
    func_name: "出库复核单记录",
    link: "/outboundReviewListRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "出库复核单记录"
  },
  {
    func_id: "17016",
    func_name: "出库复核产品记录",
    link: "/outboundReviewProductRecordsConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "出库复核产品记录"
  },
  {
    func_id: "17017",
    func_name: "销售退货产品记录",
    link: "/salesReturnRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "销售退货产品记录"
  },
  {
    func_id: "17018",
    func_name: "盘盈单",
    link: "/InventorySurplusConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "盘盈单"
  },
  {
    func_id: "17019",
    func_name: "盘盈记录",
    link: "/InventorySurplusRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "盘盈记录"
  },
  {
    func_id: "17020",
    func_name: "盘亏单",
    link: "/InventoryLossConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "盘亏单"
  },
  {
    func_id: "17021",
    func_name: "盘亏记录",
    link: "/InventoryLossRecordConsign",
    p_func_id: "1407",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "盘亏记录"
  },
]

let qxgl=[
  {
    func_id: "18001",
    func_name: "仓库设置",
    link: "/storeManageConsign",
    p_func_id: "1408",
    permission: {insert: true, delete: true, modify: true, search: true},
    rank: "3",
    title: "仓库设置"
  },
]

export default {cggl,xxgl,ckgl,bbzx,GSPgl,qxgl}